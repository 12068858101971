<template>
    <b-container>
        <b-row class="mb-5 mt-5 text-left">
            <b-col>
                <div v-if="lang != 'en'" class="WordSection1">

                <p class=MsoNormal align=center style='text-align:center'><b><span
                style='font-size:18.0pt;line-height:107%;font-family:"Times New Roman",serif'>Smluvní
                podmínky Lives</span></b></p>

                <p class=MsoNormal style='margin-left:18.0pt;text-align:justify'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Smluvní
                podmínky upravují Vaše užívání aplikace a sociální sítě Lives, s&nbsp;výjimkou
                situací, kdy výslovně stanovíme, že platí jiné, či zvláštní podmínky (nikoliv
                zmíněné smluvní podmínky), které stanovují odlišně od těchto smluvních podmínek
                informace ohledně služeb aplikace Lives.</span></p>

                <p class=MsoNormal align=center style='margin-top:0cm;margin-right:0cm;
                margin-bottom:0cm;margin-left:17.85pt;text-align:center;line-height:normal'><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif'>I.</span></p>

                <p class=MsoNormal align=center style='margin-top:0cm;margin-right:0cm;
                margin-bottom:0cm;margin-left:17.85pt;text-align:center;line-height:normal'><b><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif'>Základní
                ustanovení</span></b></p>

                <p class=MsoNormal align=center style='margin-top:0cm;margin-right:0cm;
                margin-bottom:0cm;margin-left:17.85pt;text-align:center;line-height:normal'><b><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

                <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-18.0pt'><b><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>1.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Vytvořením
                účtu v&nbsp;aplikaci „Lives“ vyjadřujete souhlas s&nbsp;těmito smluvními
                podmínkami pro užívání sociální sítě „Lives“ a stáváte se jejím uživatelem.</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>2.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Aplikace
                Lives představuje jeden z&nbsp;produktů společnosti Social technology s.r.o.,
                která je provozovatelem aplikace a sociální sítě Lives.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>3.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Smluvní
                podmínky představují smlouvu mezi Vámi jako uživatele a společností Social
                technology s.r.o. jako provozovatelem aplikace a sociální sítě Lives.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>4.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Smlouva
                mezi Vámi jako uživatelem a námi jako provozovatelem, je uzavřena dnem, kdy
                přijmete naše smluvní podmínky instalací aplikace a vstupem do sociální sítě Lives.</span></p>

                <p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>5.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Jako
                uživatel plně a bez výhrad akceptujete, že všechny produkty vytvořené aplikací
                a sociální sítí Lives, jenž se Vám v&nbsp;aplikaci nabízí, jsou výlučným
                duševním vlastnictvím jejího provozovatele, společnosti Social technology
                s.r.o.</span></p>

                <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
                text-indent:18.0pt;line-height:normal'><span style='font-size:12.5pt;
                font-family:"Times New Roman",serif'>II.</span></p>

                <p class=MsoNormal align=center style='margin-top:0cm;margin-right:0cm;
                margin-bottom:0cm;margin-left:18.0pt;text-align:center;line-height:normal'><b><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif'>Lives</span></b></p>

                <p class=MsoNormal align=center style='margin-top:0cm;margin-right:0cm;
                margin-bottom:0cm;margin-left:18.0pt;text-align:center;line-height:normal'><b><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

                <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>1.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Prohlašujeme,
                že Vám budeme poskytovat službu Lives. Služba zahrnuje všechny produkty,
                funkce, aplikace, podpůrné služby, technologie a&nbsp;software Lives, které
                poskytujeme na základě poslání Lives ať už základního, či pokročilého profilu: jehož
                smyslem a účelem je přiblížit Vás k&nbsp;lidem a&nbsp;událostem, které máte
                rádi. Lives Vám umožní vytvářet a sdílet události s&nbsp;celým světem
                v&nbsp;reálném čase, za pomocí mapového podkladu.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>2.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Poskytování
                příležitostí ohledně setkávání, vytváření zájmových komunit, komunikaci a
                objevování nových míst, příležitostí a zážitků s&nbsp;obdobně zaměřenými
                dalšími uživateli aplikace a sociální sítě Lives.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>3.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Cílem
                aplikace Lives je, abyste poznávali nové uživatele, sdíleli s&nbsp;nimi
                jakékoliv druhy událostí, či činností. Poskytujeme systém, který umožňuje
                pochopit, „koho“, nebo dokonce „co“ máte rádi a&nbsp;pomocí těchto informací
                Vám pomáhat s&nbsp;vytvářením, nacházením, účastnění se a&nbsp;sdílení událostí,
                na kterých Vám záleží.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>4.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Cílem
                Lives je vytvoření platformy bezpečné prostředí, které Vás bude bavit a umožní
                všem uživatelům propojovat společné zájmy a cíle.</span></p>

                <p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-18.0pt'><b><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>5.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>V&nbsp;souvislosti
                s&nbsp;provozováním Lives naše admin týmy a&nbsp;systémy, bojují proti
                zneužívání a&nbsp;porušování našich smluvních podmínek a&nbsp;zásad a&nbsp;také
                škodlivému a&nbsp;podvodnému jednání ze strany uživatelů. V&nbsp;rámci
                zajištění bezpečnost Lives, využíváme veškeré informace, které máme
                k&nbsp;dispozici, včetně Vašich informací. Cílem Lives je zajistit funkční a
                plně konzistentní prostředí, které Vám, uživatelům, bude maximálně vyhovovat a
                kde se budete cítit maximálně bezpečně.</span></p>

                <p class=MsoNormal align=center style='margin-top:0cm;margin-right:0cm;
                margin-bottom:0cm;margin-left:17.85pt;text-align:center;line-height:normal'><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif'>III.</span></p>

                <p class=MsoNormal align=center style='margin-top:0cm;margin-right:0cm;
                margin-bottom:0cm;margin-left:17.85pt;text-align:center;line-height:normal'><b><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif'>Financování
                aplikace „Lives“</span></b></p>

                <p class=MsoNormal align=center style='margin-top:0cm;margin-right:0cm;
                margin-bottom:0cm;margin-left:17.85pt;text-align:center;line-height:normal'><b><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

                <p class=MsoListParagraphCxSpFirst style='text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>1.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Základní
                funkcionalit aplikace „Lives“ je nabízena uživatelům bezplatně.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>2.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Bonusová
                funkcionalita je poskytována úplatně.</span></p>

                <p class=MsoListParagraphCxSpLast style='text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>3.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Poplatky
                za poskytnutí bonusové funkcionality profilu:</span></p>

                <table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0
                style='margin-left:18.0pt;border-collapse:collapse;border:none'>
                <tr>
                <td width=301 valign=top style='width:225.7pt;border:solid windowtext 1.0pt;
                padding:0cm 5.4pt 0cm 5.4pt'>
                <p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif'>Měsíční
                předplatné</span></p>
                </td>
                <td width=298 valign=top style='width:223.8pt;border:solid windowtext 1.0pt;
                border-left:none;padding:0cm 5.4pt 0cm 5.4pt'>
                <p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif'>119 Kč. Vč. DPH</span></p>
                </td>
                </tr>
                <tr>
                <td width=301 valign=top style='width:225.7pt;border:solid windowtext 1.0pt;
                border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
                <p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif'>Půlroční
                předplatné</span></p>
                </td>
                <td width=298 valign=top style='width:223.8pt;border-top:none;border-left:
                none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                padding:0cm 5.4pt 0cm 5.4pt'>
                <p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif'>599 Kč. Vč. DPH</span></p>
                </td>
                </tr>
                <tr>
                <td width=301 valign=top style='width:225.7pt;border:solid windowtext 1.0pt;
                border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
                <p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif'>Roční předplatné</span></p>
                </td>
                <td width=298 valign=top style='width:223.8pt;border-top:none;border-left:
                none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                padding:0cm 5.4pt 0cm 5.4pt'>
                <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
                0cm;margin-left:22.5pt;text-indent:-22.5pt;line-height:normal'><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif'>999<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif'>Kč. Vč. DPH</span></p>
                </td>
                </tr>
                </table>

                <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
                line-height:normal'><span style='font-size:12.5pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>

                <p class=MsoListParagraph style='margin-bottom:0cm;text-indent:-18.0pt;
                line-height:normal'><span style='font-size:12.5pt;font-family:"Times New Roman",serif'>4.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif'>Z&nbsp;důvodu
                jakéhokoliv ukončení činnosti aplikace Lives a tudíž neuběhnutí kompletní
                předplacené doby společnost Social technology s.r.o. neposkytuje náhradu
                vkladu.</span></p>

                <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
                line-height:normal'><span style='font-size:12.5pt;font-family:"Times New Roman",serif'>IV.</span></p>

                <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
                line-height:normal'><b><span style='font-size:12.5pt;font-family:"Times New Roman",serif'>Reklamní
                spoty a reklama v&nbsp;aplikaci</span></b></p>

                <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
                line-height:normal'><b><span style='font-size:12.5pt;font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

                <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>1.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Používáním
                Lives (mimo „pokročilých profilů), na kterou se vztahují zmíněné smluvní
                podmínky, vyjadřujete instalací aplikace Lives souhlas s&nbsp;tím, že Vám
                můžeme zobrazovat reklamy, za jejichž propagaci firmy a organizace, aplikací
                „Lives“, se platí. </span></p>

                <p class=MsoListParagraphCxSpLast style='text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>2.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Inzertům
                poskytujeme přehledy o úspěšnosti aplikovaných reklam, aby měli přehled, jak
                velké množství uživatelů reklama zasáhla. V&nbsp;rámci reklamy neposkytujeme
                konkrétní informace a data o našich uživatelích, s&nbsp;výhradou situace, kdy
                se na tom uživatel a provozovatel dohodnou jinak.</span></p>

                <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
                line-height:normal'><span style='font-size:12.5pt;font-family:"Times New Roman",serif'>V.</span></p>

                <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
                line-height:normal'><b><span style='font-size:12.5pt;font-family:"Times New Roman",serif'>Závazky
                uživatele vůči aplikaci „Lives“</span></b></p>

                <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
                line-height:normal'><b><span style='font-size:12.5pt;font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

                <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>1.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><b><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Uživatel
                má povinnost dodržovat závazky vůči aplikaci v&nbsp;následujícím základním
                rozsahu:</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Platné
                právní předpisy Vám nesmí zakazovat přijímání jakékoli části naší služby, nebo
                pokud jste uvedeni na platném seznamu zakázaných stran, používat služby spojené
                s&nbsp;platbami.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Účet
                uživatele nebyl v&nbsp;minulosti zablokovaný kvůli porušování zákona nebo zásad
                daných touto aplikací.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Uživatel
                nesmí být odsouzen za sexuální protiprávní jednání</span><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif;
                color:#333333'>.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Uživatel
                nesmí aplikaci využívat pro sledovací, či podobně omezovací činnost na svobodě jiných
                uživatelů.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>V&nbsp;rámci
                Lives je zakázáno, aby uživatel využíval aplikaci k&nbsp;podpoře terorismu,
                násilného jednání, protiprávních aktivit, schvalování protiprávních aktivit a
                obdobných jednání, šíření jakýchkoliv grafických nebo jinak vnímatelných
                informací se sexuálním podtextem, nebo významem.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style='font-size:12.5pt;line-height:107%;font-family:
                "Times New Roman" !important,serif'>Je zakázáno využívat Lives pro účely sjednávání
                kontaktů s&nbsp;jinými uživateli za účelem sexuálních kontaktů a obdobných
                jednání.</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style='font-size:12.5pt;line-height:107%;font-family:
                "Times New Roman" !important,serif'>Je zakázáno na „Lives“ se prezentovat či obdobně představovat
                za někoho jiného, než je Vaše pravá totožnost (cílená záměna za jinou
                existující osobu), nebo udávat nepřesné, falešné a matoucí informace.</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>2.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><b><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>V&nbsp;aplikaci
                „Lives“ nemusíte sdělovat svou pravou identitu, je přípustné se prezentovat
                prostřednictvím smyšlené přezdívky, jejíž význam nepřivodí záměnu s&nbsp;jinou
                existující osobou. </span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>3.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><b><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Je
                zakázáno na osobním profilu uživatele, využívat cizí údaje, fotografie, či
                jakékoliv jiné informace a údaje, které by mohly poškodit jinou existující
                osobu. Výjimku z&nbsp;tohoto zákazu tvoří situace, kdy pro užití identity jiné
                osoby, má uživatel předchozí písemné svolení. </span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>4.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><b><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Je
                zakázáno využívat aplikaci „Lives“ k&nbsp;nezákonným činnostem, klamavému nebo
                podvodnému jednání, nebo činnostem sloužících nelegálnímu nebo neschválenému
                účelu.</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>5.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><b><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Je
                zakázáno využívat Lives podpoře a navádění jiné osoby zakázaným, podvodným nebo
                jinak protiprávním činnostem. </span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>6.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><b><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Je
                zakázáno využívat Lives pro sledovací, či podobně omezující činnosti, které
                ingerují nebo jinak zasahují do svobody a osobní integrity jiného uživatele. </span></b><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Zejména
                se jednání o fyzické sledování polohovacího zařízení, jestliže si to druhá
                osoba nepřeje, či o činnosti neví a jakkoliv omezovat uživatelovu svobodu a
                volný pohyb.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>7.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><b><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Je
                zakázáno publikovat a vytvářet smyšlené události, které by vedly
                k&nbsp;poškození druhé osoby</span></b><span style='font-size:12.5pt;
                line-height:107%;font-family:"Times New Roman",serif'>. Zejména vytvářet
                události, které mohou vzniknout za účelem nalákáním druhé osoby, s&nbsp;klamavým
                obsahem, které vznikají za účelem ublížení na zdraví, či jinému poškození zájmů
                druhé osoby).</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>8.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><b><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Je
                zakázáno využívat funkcionality Lives k&nbsp;protiprávním činnostem.</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>9.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><b><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Je
                zakázáno provádět jakékoliv činnosti, které by mohly narušit správné fungování
                služby.</span></b><span style='font-size:12.5pt;line-height:107%;font-family:
                "Times New Roman",serif'> (např. bezdůvodné hlášení a upozornění, bezdůvodné
                nahlášení profilů, bezdůvodné stížnosti, či jiné útoky vedené proti aplikaci).</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>10.<span
                style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><b><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Je
                zakázané vytvářet účty, které budou využity na získávání informací
                neschválenými způsoby. </span></b><span style='font-size:12.5pt;line-height:
                107%;font-family:"Times New Roman",serif'>(např. vytváření účtů, nebo získávání
                informací automatizovaným způsobem bez výslovného povolení provozovatele).</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-right:28.8pt;text-align:justify;
                text-indent:-18.0pt;line-height:normal;background:white'><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif'>11.<span
                style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><b><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif;color:black'>Je
                zakázané prodávat, licencovat či kupovat účty či údaje shromážděné provozovatelem,
                či aplikací Lives. </span></b><span style='font-size:12.5pt;font-family:"Times New Roman",serif;
                color:black'>To zahrnuje pokusy o&nbsp;koupi, prodej či převod jakékoli části
                vašeho účtu, včetně vašeho uživatelského jména), stejně tak jako
                o&nbsp;nabízení, shromažďování či používání přihlašovacích údajů či štítků
                jiných uživatelů, nebo vyžadování či shromažďování uživatelských jmen
                a&nbsp;hesel na „Lives“ či nevhodné přisvojení si přístupových tokenů.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>12.<span
                style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><b><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Je
                zakázané modifikovat, překládat nebo vytvářet odvozená díla, či zpětné analýzy
                produktů provozovatele nebo jejich komponentů.</span></b></p>

                <p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>13.<span
                style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><b><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Bez
                předchozího písemného svolení provozovatele je zakázáno v&nbsp;uživatelském
                jménu použít název domény, nebo URL.</span></b></p>

                <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
                line-height:normal'><span style='font-size:12.5pt;font-family:"Times New Roman",serif'>VI.</span></p>

                <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
                line-height:normal'><b><span style='font-size:12.5pt;font-family:"Times New Roman",serif'>Oprávnění,
                která uděluje uživatel provozovateli</span></b></p>

                <p class=MsoNormal><b><span style='font-size:12.5pt;line-height:107%;
                font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

                <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>1.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><b><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Provozovatel
                neuplatňujeme nárok na vlastnictví obsahu uživatele, ale uživatel uděluje
                provozovateli licenci na jeho používání.<br>
                </span></b><span style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Vaše
                práva spojená s&nbsp;obsahem se nijak nemění. Nečiníme si žádný nárok na
                vlastnictví vašeho obsahu, který zveřejňujete v aplikaci nebo jejím
                prostřednictvím, a svůj obsah můžete sdílet s kýmkoli jiným a kdekoli chcete. K
                poskytování aplikace však od vás potřebujeme určitá zákonná oprávnění (známá
                jako „licence“). Když v&nbsp;naší aplikaci nebo ve spojení s&nbsp;ní sdílíte,
                zveřejňujete nebo nahráváte obsah, na který se vztahují práva k&nbsp;duševnímu
                vlastnictví (například fotografie nebo videa), udělujete provozovateli tímto
                nevýhradní, bezplatnou, převoditelnou, dále licencovatelnou,&nbsp;celosvětovou
                licenci k&nbsp;hostování, používání, distribuci, úpravě, spouštění, kopírování,
                veřejnému předvádění nebo zobrazování, překladu a vytváření odvozených děl
                k&nbsp;vašemu obsahu (odpovídající vašemu nastavení ochrany soukromí
                a&nbsp;aplikace). Platnost této licence končí v&nbsp;okamžiku, kdy je obsah
                uživatele odstraněn ze&nbsp;systémů provozovatele. Obsah můžete odstraňovat po
                jednotlivých položkách, případně ho můžete odstraněním svého účtu odstranit
                všechen najednou.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>2.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><b><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Oprávnění
                pracovat s&nbsp;Vaším uživatelským jménem, profilovým obrázkem, informacemi na
                Vašem profilu nebo vyhodnocovat, které vytvořené události jsou pro provozovatele
                nejzajímavější</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>3.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Uživatel
                uděluje provozovateli oprávnění sledovat, ukazovat a pracovat
                s&nbsp;uživatelským jménem, profilovými obrázky, informacemi nebo uživatelem
                vytvořenými událostmi. Provozovatel může zpracovat a ukázat data o nejčastěji
                frekventovaných společenských událostech v&nbsp;daném okolí, či si vytvářet
                interní statistiku.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>4.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><b><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Uživatel
                souhlasí se sledováním jeho polohy ve chvíli, kdy zmíněnou funkci bude
                využívat.</span></b></p>

                <p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>5.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><b><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif;
                color:#333333;background:white'>Uživatel souhlasí s&nbsp;tím, že provozovatel může
                do uživatelova zařízení stahovat a&nbsp;instalovat aktualizace služby.</span></b></p>

                <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
                line-height:normal'><span style='font-size:12.5pt;font-family:"Times New Roman",serif'>VII.</span></p>

                <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
                line-height:normal'><b><span style='font-size:12.5pt;font-family:"Times New Roman",serif'>Další
                oprávnění</span></b></p>

                <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>1.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Jestliže
                uživatel pro svůj účet vybere uživatelské jméno nebo jiný identifikátor, může
                jej provozovatel změnit, pokud to budeme považovat za vhodné nebo nezbytné
                (například pokud porušuje něčí práva k&nbsp;duševnímu vlastnictví, nebo se
                vydává za jiného uživatele).</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>2.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Jestliže
                uživatel používá obsah, na který se vztahují práva k&nbsp;duševnímu
                vlastnictví, který má provozovatel a&nbsp;který ve službě zpřístupňuje, například
                provozovatelem poskytované obrázky, design, videa nebo zvuky, které může
                uživatel přidat k&nbsp;vytvářenému nebo sdílenému obsahu, provozovatel si
                uchovává všechna práva k&nbsp;jeho obsahu.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>3.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Duševní
                vlastnictví provozovatele nebo ochranné známky provozovatele (nebo podobné
                známky) může uživatel používat pouze s&nbsp;výslovným souhlasem společnosti
                Social technology s.r.o.</span></p>

                <p class=MsoListParagraphCxSpLast style='text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>4.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Chce-li
                uživatel upravovat, vytvářet odvozená díla, dekompilovat nebo se jinak pokoušet
                extrahovat zdrojový kód provozovatele, musí mít od provozovatele předchozí písemný
                souhlas.</span></p>

                <p class=MsoNormal align=center style='text-align:center'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>VIII.</span></p>

                <p class=MsoNormal align=center style='text-align:center'><b><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Zásahy
                obsahu, blokování či zrušení účtu uživatele</span></b></p>

                <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>1.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Jakýkoli
                obsah nebo informace, které ve službě uživatel sdílí, může provozovatel
                odebrat, pokud podle názoru provozovatele porušují tyto smluvní podmínky,
                zásady aplikace Lives, nebo pokud to vyžadují právní předpisy.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>2.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Obsah,
                který byl hlášený, čí zablokovaný jiným uživatel, který vyhodnotil provozovatel
                jako škodlivý, či porušující smluvní podmínky, může provozovatel odebrat nebo
                jinak znepřístupnit v&nbsp;aplikaci Lives.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>3.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Službu
                nebo její část může provozovatel okamžitě odmítnout, nebo přestat poskytovat
                s&nbsp;cílem ochránit jiné uživatele, nebo ochránit statut aplikace Lives, nebo
                pokud je provozovatel vystaven činností uživatele riziku či možnosti trestního
                stíhání, zejména v&nbsp;důsledku porušování těchto smluvních podmínek, či zásad
                provozovatele, pokud uživatel opakovaně porušuje práva jiných osob
                k&nbsp;duševnímu vlastnictví, nebo pokud to vyžadují právní předpisy.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>4.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Provozovatel
                můžeme Službu ukončit nebo ji změnit, odstranit nebo zablokovat obsah nebo
                údaje sdílené v rámci Služby, případně přestat Službu nebo jakoukoli její část
                poskytovat, pokud se rozhodne, že je to přiměřeně nutné k tomu, aby zabránil
                negativním právním nebo regulatorním dopadům, nebo aby takové dopady zmírnil.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>5.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Rozhodnutí
                provozovatele odebrat obsah z&nbsp;uživatelské adresy, bude uživateli oznámen s
                možností přezkoumání žádosti uživatele o obnovení obsahu, avšak s&nbsp;výjimkou
                případů, kdy závažně nebo opakovaně uživatel poruší tyto smluvní podmínky, nebo
                kdyby tento krok vystavil provozovatele právní odpovědnosti, nebo&nbsp;poškodil
                jiného uživatele, ohrozil nebo narušil integritu nebo provoz jakýchkoli služeb,
                systémů nebo produktů provozovatele, nebo kdy to znemožňují technická omezení
                aplikace Lives, nebo zakazují právní předpisy.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>6.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Uživatel
                je oprávněn v případě, že mu je účet zrušen omylem, nebo pokud chce účet
                zablokovat nebo trvale odstranit, požadovat od provozovatele a postupovat při
                tomto jednání dle informací aplikace. Pokud požádá uživatel o odstranění obsahu
                nebo svého účtu, proces odstranění automaticky začne nejpozději do 30 dnů od
                data žádosti. Proces odstraňování obsahu může trvat až 90&nbsp;dnů od zahájení.
                Během procesu odstraňování takového obsahu již tento obsah není viditelný pro
                ostatní uživatele, ale nadále podléhá zmíněným smluvním podmínkám. Po
                odstranění obsahu může provozovateli trvat dalších 90 dnů, než odstraní obsah
                účtu uživatele ze záloh a krizových systémů pro obnovu dat.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>7.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><b><span
                style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif;
                color:#333333;background:white'>Obsah účtu uživatele nebude odstraněn do 90 dnů
                od zahájení procesu odstranění účtu nebo odstranění obsahu v následujících
                situacích:</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-right:28.8pt;margin-left:
                72.0pt;text-align:justify;text-indent:-36.0pt;line-height:normal;background:
                white'><span style='font-size:12.5pt;font-family:"Times New Roman",serif'>7.1.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style='font-size:12.5pt;font-family:"Times New Roman",serif;
                color:black'>pokud obsah účtu uživatele v&nbsp;souladu s&nbsp;touto licencí
                použily jiné osoby a&nbsp;neodstranily ho, v&nbsp;takovém případě bude tato
                licence nadále v&nbsp;platnosti až do okamžiku odstranění daného obsahu.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-right:28.8pt;margin-left:
                72.0pt;text-align:justify;text-indent:-36.0pt;line-height:normal;background:
                white'><span style='font-size:12.5pt;font-family:"Times New Roman",serif'>7.2.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style='font-size:12.5pt;font-family:"Times New Roman",serif;
                color:black'>pokud odstranění do 90 dnů není možné z důvodu technických omezení
                systémů provozovatele, v takovém případě proces odstranění provozovatel dokončí,
                jakmile to bude technicky možné.</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-right:28.8pt;margin-left:
                72.0pt;text-align:justify;text-indent:-36.0pt;line-height:normal;background:
                white'><span style='font-size:12.5pt;font-family:"Times New Roman",serif'>7.3.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style='font-size:12.5pt;font-family:"Times New Roman",serif;
                color:black'>pokud by odstranění zamezilo dalším veřejným zájmům:</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-right:28.8pt;margin-left:
                90.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;background:
                white'><span style='font-size:12.5pt;font-family:"Times New Roman",serif'>a)<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif;color:black'>prošetřit
                nebo identifikovat nelegální činnost nebo porušení smluvních podmínek
                a&nbsp;zásad provozovatele (například identifikovat nebo prošetřit zneužití
                produktů nebo systémů provozovatele);</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-right:28.8pt;margin-left:
                90.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal;background:
                white'><span style='font-size:12.5pt;font-family:"Times New Roman",serif'>b)<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif;color:black'>chránit
                bezpečnost a zabezpečení produktů a systémů provozovatele, nebo jiných
                uživatelů;</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-right:28.8pt;margin-left:
                90.0pt;text-indent:-18.0pt;line-height:normal;background:white'><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif'>c)<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif;color:black'>splnit
                právní povinnosti, jako je zachování důkazů;</span></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-right:28.8pt;margin-left:
                90.0pt;text-indent:-18.0pt;line-height:normal;background:white'><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif'>d)<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif;color:black'>vyhovět
                žádosti ze strany soudního nebo správního orgánu, bezpečnostní složky státu; </span></p>

                <p class=MsoListParagraphCxSpMiddle><span style='font-size:12.5pt;line-height:
                107%;font-family:"Times New Roman",serif'>&nbsp;</span></p>

                <p class=MsoListParagraphCxSpLast style='margin-right:28.8pt;text-align:justify;
                text-indent:-18.0pt;line-height:normal;background:white'><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif'>8.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif;color:black'>Pokud
                svůj účet odstraní uživatel sám, nebo pokud jej zablokuje, budou tyto smluvní
                podmínky jako dohoda mezi uživatelem a provozovatelem ukončeny, ale tento oddíl
                a výše uvedený oddíl s názvem „<b>Zásahy obsahu, blokování či zrušení účtu
                uživatele</b>“ budou platit i po zrušení, zablokování, nebo odstranění účtu
                uživatele.</span></p>

                <p class=MsoNormal align=center style='margin-top:0cm;margin-right:28.9pt;
                margin-bottom:0cm;margin-left:0cm;text-align:center;line-height:normal;
                background:white'><span style='font-size:12.5pt;font-family:"Times New Roman",serif;
                color:black'>IX.</span></p>

                <p class=MsoNormal align=center style='margin-top:0cm;margin-right:28.9pt;
                margin-bottom:0cm;margin-left:0cm;text-align:center;line-height:normal;
                background:white'><b><span style='font-size:12.5pt;font-family:"Times New Roman",serif;
                color:black'>Odpovědnost</span></b></p>

                <p class=MsoListParagraphCxSpFirst style='margin-top:0cm;margin-right:28.9pt;
                margin-bottom:0cm;margin-left:36.0pt;line-height:normal;background:white'><b><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

                <p class=MsoListParagraphCxSpMiddle style='margin-right:28.8pt;text-align:justify;
                text-indent:-18.0pt;line-height:normal;background:white'><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif'>1.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif;color:black'>Prioritní
                snahou aplikace, sociální sítě „Lives“ a jeho týmu je poskytnout uživatelům
                maximální servis o co nejvíce bezpečnou a bezchybnou aplikaci. Nelze ovšem
                zaručit a ohlídat veškeré aktivity, které se v&nbsp;aplikaci mohou stát. Proto
                každý uživatel, který souhlasí se&nbsp;smluvními podmínkami souhlasí s&nbsp;užíváním
                aplikace „Lives“ a zároveň si je vědom možných rizik, které aplikace přináší a
                přebírá za ně plnou odpovědnost (všechny formy ublížení na zdraví, smrt,
                zranění osob, násilná jednání se sexuálním nebo jiným obdobným podtextem,
                podvodně zkreslená a učiněná prohlášení) za které Social technology s.r.o.
                nepřijímá odpovědnost.</span></p>

                <p class=MsoListParagraphCxSpLast style='margin-right:28.8pt;text-align:justify;
                text-indent:-18.0pt;line-height:normal;background:white'><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif'>2.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif;color:black'>Společnost
                Social technology s.r.o. nenese odpovědnost v&nbsp;situacích, které právní
                předpisy neumožňují, nebo zakazují.</span></p>

                <p class=MsoNormal align=center style='margin-top:0cm;margin-right:28.9pt;
                margin-bottom:0cm;margin-left:0cm;text-align:center;line-height:normal;
                background:white'><span style='font-size:12.5pt;font-family:"Times New Roman",serif;
                color:black'>X.</span></p>

                <p class=MsoNormal align=center style='margin-top:0cm;margin-right:28.9pt;
                margin-bottom:0cm;margin-left:0cm;text-align:center;line-height:normal;
                background:white'><b><span style='font-size:12.5pt;font-family:"Times New Roman",serif;
                color:black'>Případné soudní spory</span></b></p>

                <p class=MsoListParagraphCxSpFirst style='margin-right:28.8pt;text-align:justify;
                text-indent:-18.0pt;line-height:normal;background:white'><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif'>1.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif;color:black'>Jestliže
                z používání služby v postavení spotřebitele vznikne nárok nebo spor o nárok, uživatel
                i provozovatel souhlasí s tím, že můžete vyřešit svůj individuální nárok nebo
                spor o nárok proti sobě navzájem výhradně dle právního řádu České republiky
                s&nbsp;tím, že věcně a místně příslušným k&nbsp;projednáním a rozhodnutím sporu
                je soud provozovatele.</span></p>

                <p class=MsoListParagraphCxSpLast style='margin-right:28.8pt;text-align:justify;
                text-indent:-18.0pt;line-height:normal;background:white'><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif'>2.<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif;color:black'>Pokud
                mezi uživatelem a provozovatelem vznikne nárok nebo spor o nárok, který souvisí
                s používáním služby na jakémkoli jiném základě, včetně přístupu ke službě nebo
                používání služby pro firemní nebo obchodní účely, souhlasí smluvní strany s
                tím, že jakýkoli takový nárok nebo spor o nárok bude řešen výhradně dle
                právního řádu České republiky s&nbsp;tím, že věcně a místně příslušným
                k&nbsp;projednáním a rozhodnutím sporu je soud provozovatele.</span></p>

                <p class=MsoNormal align=center style='margin-top:0cm;margin-right:28.9pt;
                margin-bottom:0cm;margin-left:0cm;text-align:center;line-height:normal;
                background:white'><span style='font-size:12.5pt;font-family:"Times New Roman",serif;
                color:black'>XI.</span></p>

                <p class=MsoNormal align=center style='margin-top:0cm;margin-right:28.9pt;
                margin-bottom:0cm;margin-left:0cm;text-align:center;line-height:normal;
                background:white'><b><span style='font-size:12.5pt;font-family:"Times New Roman",serif;
                color:black'>Aktualizace těchto smluvních podmínek</span></b></p>

                <p class=MsoNormal align=center style='margin-top:0cm;margin-right:28.9pt;
                margin-bottom:0cm;margin-left:0cm;text-align:center;line-height:normal;
                background:white'><b><span style='font-size:12.5pt;font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

                <p class=MsoNormal style='margin-top:0cm;margin-right:28.9pt;margin-bottom:
                0cm;margin-left:0cm;text-align:justify;line-height:normal;background:white'><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif;color:black'>Uživatel
                je výslovně srozuměn s&nbsp;tím, že aplikace a sociální síť Lives je postupně
                zdokonalován do budoucna s&nbsp;potřebami této poskytované služby a jeho změny z&nbsp;důvodu
                zlepšení uživatelských služeb, budou přinášet nové produkty, a z&nbsp;toho
                důvodu mohou být měněny smluvní podmínky. Pokud právní předpisy nevyžadují
                jinak, alespoň třicet dní před provedením změn těchto smluvních podmínek
                upozorní provozovatel nejméně prostřednictvím provozovatelovi služby na tyto
                změny smluvních podmínek a&nbsp;dá uživateli takto možnost se s&nbsp;jejich
                obsahem seznámit. Pokud bude uživatel služby provozovatele i&nbsp;nadále
                používat, budou platit již smluvní podmínky ve znění jejich změn a doplnění
                k&nbsp;aktuální době, kdy je služba uživatelem používána. Pokud s&nbsp;těmito
                nebo případnými aktualizovanými smluvními podmínkami uživatel nesouhlasí, je
                oprávněn svůj učet odstranit.<b></b></span></p>

                <p class=MsoNormal style='margin-top:0cm;margin-right:28.9pt;margin-bottom:
                0cm;margin-left:0pt;text-align:justify;line-height:normal;background:white'><b><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif;color:black'></span></b><span
                style='font-size:12.5pt;font-family:"Times New Roman",serif;color:black'>Social
                technology s.r.o., provozovatel</span></p>

                </div>
                <div v-else class="WordSection1" >
                    <p class=MsoNormal align=center style='text-align:center'><b><span
                    style='font-size:18.0pt;line-height:107%;font-family:"Times New Roman",serif'>Terms
                    and Conditions Lives</span></b></p>

                    <p class=MsoNormal style='margin-left:18.0pt;text-align:justify'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>The
                    Terms and Conditions govern your use of the Lives Application and Social
                    Networks, except where we expressly state that different or special terms and
                    conditions (not the said Terms and Conditions) apply, which set out information
                    about the Lives Services services differently from these Terms and Conditions.</span></p>

                    <p class=MsoNormal align=center style='margin-top:0cm;margin-right:0cm;
                    margin-bottom:0cm;margin-left:17.85pt;text-align:center;line-height:normal'><b><span
                    style='font-size:12.5pt;font-family:"Times New Roman",serif'>I.</span></b></p>

                    <p class=MsoNormal align=center style='margin-top:0cm;margin-right:0cm;
                    margin-bottom:0cm;margin-left:17.85pt;text-align:center;line-height:normal'><b><span
                    style='font-size:12.5pt;font-family:"Times New Roman",serif'>Basic provision</span></b></p>

                    <p class=MsoNormal><span style='font-size:12.5pt;line-height:107%;font-family:
                    "Times New Roman",serif'>&nbsp;</span></p>

                    <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>1<b>.
                    By creating an account in the &quot;Lives&quot; application, you agree to these
                    terms and conditions for the use of the &quot;Lives&quot; social network and
                    become its user.</b></span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>2.
                    The Lives application is one of the products of the company Social technology
                    s.r.o., which is the operator of the application and the social network Lives.</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>3.
                    The contractual conditions represent an agreement between you as the user and
                    the company Social technology s.r.o. as the operator of the application and
                    social network Lives.</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>4.
                    The contract between you as a user and us as the operator is concluded on the
                    day you accept our terms and conditions by installing the application and
                    entering the social network Lives.</span></p>

                    <p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>5.
                    As a user, you fully and unconditionally accept that all products created by
                    the application and the Lives social network offered to you in the application
                    are the exclusive intellectual property of its operator, Social technology
                    s.r.o.</span></p>

                    <p class=MsoNormal align=center style='text-align:center;text-indent:35.4pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>II.</span></b></p>

                    <p class=MsoNormal align=center style='text-align:center;text-indent:35.4pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Lives</span></b></p>

                    <p class=MsoNormal style='text-indent:35.4pt'><span style='font-size:12.5pt;
                    line-height:107%;font-family:"Times New Roman",serif'>&nbsp;</span></p>

                    <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-18.0pt'><span
                    lang=EN-US style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>1</span><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>.
                    We declare that we will provide you with the Lives service. The service
                    includes all Lives products, features, applications, support services,
                    technologies and software, which we provide based on Lives' mission, whether
                    basic or advanced profile: the purpose and purpose of which is to bring you
                    closer to the people and events you love. Lives allow you to create and share
                    events with the world in real time, using a map background.</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>2.
                    Providing opportunities to meet, create communities of interest, communicate
                    and discover new places, opportunities and experiences with similarly oriented
                    other users of the application and social network Lives.</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>3.
                    The goal of the Lives application is for you to meet new users, share any kind
                    of events or activities with them. We provide a system that allows you to
                    understand &quot;who&quot; or even &quot;what&quot; you love and with this
                    information to help you create, find, participate and share the events you care
                    about.</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>4.
                    The goal of Lives is to create a platform-safe environment that will entertain
                    you and allow all users to connect common interests and goals.</span></p>

                    <p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>5.
                    In connection with the operation of Lives, our admin teams and systems fight
                    against abuse and violation of our terms and conditions and policies, as well
                    as harmful and fraudulent conduct by users. To ensure the security of Lives, we
                    use all the information we have at our disposal, including your information.
                    The goal of Lives is to ensure a functional and fully consistent environment
                    that will suit you, the users, as much as possible and where you will feel as
                    safe as possible.</span></p>

                    <p class=MsoNormal align=center style='text-align:center;text-indent:35.4pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>III.</span></b></p>

                    <p class=MsoNormal align=center style='text-align:center;text-indent:35.4pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Lives
                    funding</span></b></p>

                    <p class=MsoNormal style='text-indent:35.4pt'><span style='font-size:12.5pt;
                    line-height:107%;font-family:"Times New Roman",serif'>&nbsp;</span></p>

                    <p class=MsoListParagraphCxSpFirst style='text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>1.
                    The basic functionality of the &quot;Lives&quot; application is offered to
                    users free of charge.</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>2.
                    Bonus functionality is provided for a fee.</span></p>

                    <p class=MsoListParagraphCxSpLast style='text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>3.
                    Fees for providing bonus profile functionality:</span></p>

                    <table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0 width=603
                    style='width:452.4pt;margin-left:18.0pt;border-collapse:collapse;border:none'>
                    <tr style='height:22.65pt'>
                    <td width=303 valign=top style='width:227.15pt;border:solid windowtext 1.0pt;
                    padding:0cm 5.4pt 0cm 5.4pt;height:22.65pt'>
                    <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
                    line-height:normal'><span lang=EN-US style='font-size:12.5pt;font-family:
                    "Times New Roman",serif'>Monthly subscription 119 CZK. Incl. VAT</span></p>
                    </td>
                    <td width=300 valign=top style='width:225.25pt;border:solid windowtext 1.0pt;
                    border-left:none;padding:0cm 5.4pt 0cm 5.4pt;height:22.65pt'>
                    <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
                    line-height:normal'><span lang=EN-US style='font-size:12.5pt;font-family:
                    "Times New Roman",serif'>Monthly subscription 119 CZK. Incl. VAT</span></p>
                    </td>
                    </tr>
                    <tr style='height:22.65pt'>
                    <td width=303 valign=top style='width:227.15pt;border:solid windowtext 1.0pt;
                    border-top:none;padding:0cm 5.4pt 0cm 5.4pt;height:22.65pt'>
                    <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
                    line-height:normal'><span lang=EN-US style='font-size:12.5pt;font-family:
                    "Times New Roman",serif'>Half-year subscription CZK 599. Incl. VAT</span></p>
                    </td>
                    <td width=300 valign=top style='width:225.25pt;border-top:none;border-left:
                    none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                    padding:0cm 5.4pt 0cm 5.4pt;height:22.65pt'>
                    <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
                    line-height:normal'><span lang=EN-US style='font-size:12.5pt;font-family:
                    "Times New Roman",serif'>Half-year subscription CZK 599. Incl. VAT</span></p>
                    </td>
                    </tr>
                    <tr style='height:22.65pt'>
                    <td width=303 valign=top style='width:227.15pt;border:solid windowtext 1.0pt;
                    border-top:none;padding:0cm 5.4pt 0cm 5.4pt;height:22.65pt'>
                    <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
                    line-height:normal'><span lang=EN-US style='font-size:12.5pt;font-family:
                    "Times New Roman",serif'>Annual subscription CZK 999. Incl. VAT</span></p>
                    </td>
                    <td width=300 valign=top style='width:225.25pt;border-top:none;border-left:
                    none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
                    padding:0cm 5.4pt 0cm 5.4pt;height:22.65pt'>
                    <p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
                    style='font-size:12.5pt;font-family:"Times New Roman",serif'>Annual subscription
                    CZK 999. Incl. VAT</span></p>
                    </td>
                    </tr>
                    </table>

                    <p class=MsoListParagraphCxSpFirst style='margin-bottom:0cm;text-indent:-18.0pt;
                    line-height:normal'><span style='font-size:12.5pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;text-indent:-18.0pt;
                    line-height:normal'><span style='font-size:12.5pt;font-family:"Times New Roman",serif'>4.
                    Due to any termination of the Lives application and therefore failure to
                    complete the full prepaid period, Social technology s.r.o. does not provide
                    deposit compensation.</span></p>

                    <p class=MsoListParagraphCxSpLast style='margin-bottom:0cm;text-indent:-18.0pt;
                    line-height:normal'><span style='font-size:12.5pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>

                    <p class=MsoNormal align=center style='text-align:center;text-indent:35.4pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>IV.</span></b></p>

                    <p class=MsoNormal align=center style='text-align:center;text-indent:35.4pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Advertising
                    spots and in-app advertising</span></b></p>

                    <p class=MsoNormal><b><span style='font-size:12.5pt;line-height:107%;
                    font-family:"Times New Roman",serif'>&nbsp;</span></b></p>

                    <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>1.
                    By using Lives (other than the &quot;Advanced Profiles&quot;), which are
                    subject to these terms and conditions, by installing the Lives application, you
                    agree that we may show you ads for which the &quot;Lives&quot; application
                    promotes the company and organization.</span></p>

                    <p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>2.
                    We provide advertisers with reports on the success of their applied ads so that
                    they have an overview of how many users have been affected by the ad. We do not
                    provide specific information and data about our users in advertising, unless
                    the user and the operator agree otherwise.</span></p>

                    <p class=MsoNormal align=center style='text-align:center;text-indent:35.4pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>V.</span></b></p>

                    <p class=MsoNormal align=center style='text-align:center;text-indent:35.4pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>User
                    Liabilities to Lives</span></b></p>

                    <p class=MsoNormal style='text-indent:35.4pt'>&nbsp;</p>

                    <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>1.
                    <b>The user is obliged to comply with the obligations towards the application
                    to the following basic extent:</b></span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>-
                    The applicable law must not prohibit you from accepting any part of our service
                    or, if you are on a valid list of prohibited parties, from using
                    payment-related services.</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>-
                    The user's account has not been suspended in the past due to violations of the
                    law or policies of this application.</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>-
                    The user must not be convicted of sexual offenses.</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>-
                    The user may not use the application for monitoring or similar restriction
                    activities at the discretion of other users.</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>-
                    Within Lives, it is forbidden for the user to use the application to support
                    terrorism, violence, illegal activities, approval of illegal activities and
                    similar acts, dissemination of any graphic or otherwise perceptible information
                    with a sexual subtext or meaning.</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>-
                    It is forbidden to use Lives for the purpose of arranging contacts with other
                    users for the purpose of sexual contacts and similar acts.</span></b></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>-
                    It is forbidden to present or similarly present yourself on behalf of
                    &quot;Lives&quot; for anyone other than your true identity (targeted exchange
                    for another existing person) or to give inaccurate, false and confusing
                    information.</span></b></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>2.
                    In the &quot;Lives&quot; application, you do not have to disclose your true
                    identity, it is permissible to present yourself through a fictitious nickname,
                    the meaning of which does not lead to confusion with another existing person.</span></b></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>3.
                    It is forbidden to use other people's data, photographs or any other
                    information and data on the user's personal profile that could harm another
                    existing person. An exception to this prohibition is a situation where the user
                    has prior written permission to use the identity of another person.</span></b></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>4.
                    It is forbidden to use the &quot;Lives&quot; application for illegal
                    activities, deceptive or fraudulent activities, or activities serving an
                    illegal or unapproved purpose.</span></b></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>5.
                    It is prohibited to use Lives to support or induce another person to engage in
                    prohibited, fraudulent or otherwise unlawful activities.</span></b></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>6.
                    It is prohibited to use Lives for surveillance or similarly restrictive
                    activities that interfere with or otherwise interfere with the freedom and
                    personal integrity of another user.</span></b><span style='font-size:12.5pt;
                    line-height:107%;font-family:"Times New Roman",serif'> In particular,
                    negotiations on the physical tracking of the positioning device, if the other
                    person does not wish to do so, or does not know about the activity, and in any
                    way restrict the user's freedom and freedom of movement.</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>7.
                    <b>It is forbidden to publish and create fictional events that would lead to
                    harm to another person.</b> In particular, to create events that may arise for
                    the purpose of attracting another person, with misleading content, which arise
                    for the purpose of personal injury or other harm to the interests of the other
                    person).</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>8<b>.
                    It is forbidden to use the Lives functionality for illegal activities.</b></span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>9.
                    <b>It is forbidden to perform any activities that could interfere with the
                    proper functioning of the service.</b> (fx. unreasonable reporting and alerts,
                    unreasonable reporting of profiles, unreasonable complaints, or other attacks
                    against the application).</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>10.
                    <b>It is prohibited to create accounts that will be used to obtain information
                    in unauthorized ways. (eg creating accounts or obtaining information in an
                    automated way without the express permission of the operator).</b></span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>11.
                    <b>It is forbidden to sell, license or buy accounts or data collected by the
                    operator or the Lives application.</b> This includes attempts to buy, sell or
                    transfer any part of your account, including your username), as well as offer,
                    collect or use other users' logins or labels, or require or collect usernames
                    and passwords to &quot;Lives&quot; or inappropriate appropriation of access
                    tokens.</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>12.
                    <b>It is forbidden to modify, translate or create derivative works or
                    retrospective analyzes of the operator's products or their components.</b></span></p>

                    <p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>13.
                    <b>It is forbidden to use a domain name or URL in the username without the
                    prior written consent of the operator.</b></span></p>

                    <p class=MsoNormal align=center style='text-align:center;text-indent:35.4pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>VI.</span></b></p>

                    <p class=MsoNormal align=center style='text-align:center;text-indent:35.4pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Authorizations
                    granted by the user to the operator</span></b></p>

                    <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>1.
                    <b>The operator does not claim ownership of the user's content, but the user
                    licenses the operator to use it.</b></span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Your
                    content rights do not change in any way. We do not claim ownership of your
                    content that you post to or through the app, and you may share your content
                    with anyone else and wherever you want. However, we need certain legal rights
                    (known as &quot;licenses&quot;) from you to provide the application. When you
                    share, post or upload intellectual property content (such as photos or videos)
                    in or in connection with our application, you hereby grant the operator a
                    non-exclusive, free, transferable, sublicensable, worldwide license to host,
                    use, distribute, modify, run, copy, publicly perform or display, translate and
                    create derivative works of your content (appropriate to your privacy and
                    application settings). This license expires when the user's content is removed
                    from the operator's systems. You can delete content item by item, or you can
                    delete it at once by deleting your account.</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>2<b>.
                    Authorization to work with your username, profile picture, recorded gps
                    location, information on your profile or to evaluate which created events are
                    the most interesting for the operator</b></span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>3.
                    The user grants the operator the right to monitor, show and work with the user
                    name, profile pictures, recorded gps location, information or user-generated
                    events. The operator can process and show data on the most frequent social
                    events in the area, or create internal statistics.</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>4.
                    <b>The user agrees to monitor his position when he uses the mentioned function.</b></span></p>

                    <p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-18.0pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>5.
                    The user agrees that the operator may download and install service updates to
                    the user's device.</span></b></p>

                    <p class=MsoNormal align=center style='text-align:center;text-indent:35.4pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>VII.</span></b></p>

                    <p class=MsoNormal align=center style='text-align:center;text-indent:35.4pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Additional
                    permissions</span></b></p>

                    <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>1.
                    If a user chooses a username or other identifier for his account, the operator
                    may change it if we deem it appropriate or necessary (for example, if he
                    infringes someone's intellectual property rights or impersonates another user).</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>2.
                    If a user uses content which is the subject of intellectual property rights
                    held by the operator and which he makes available in the service, such as
                    images, designs, videos or sounds provided by the operator which the user may
                    add to the content created or shared, the operator shall retain all: rights to
                    its content.</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>3.
                    The intellectual property of the operator or the operator's trademarks (or
                    similar trademarks) may be used by the user only with the express consent of
                    Social technology s.r.o.</span></p>

                    <p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>4.
                    If the user wishes to modify, create derivative works from, decompile or
                    otherwise attempt to extract the source code of the operator, he must have the
                    prior written consent of the operator.</span></p>

                    <p class=MsoNormal align=center style='text-align:center;text-indent:35.4pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>VIII.</span></b></p>

                    <p class=MsoNormal align=center style='text-align:center;text-indent:35.4pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Content
                    interference, blocking or cancellation of the user's account</span></b></p>

                    <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>1.
                    The operator may remove any content or information shared by the user in the
                    service if, in the opinion of the operator, they violate these terms and
                    conditions, the Lives application policy, or if required by law.</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>2.
                    Content that has been reported or blocked by another user who has been
                    evaluated by the operator as harmful or in breach of the terms and conditions
                    may be removed by the operator or otherwise inaccessible in the Lives
                    application.</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>3.
                    The Operator may immediately refuse or cease providing the Service, or cease to
                    provide it, in order to protect other users or protect the status of the Lives
                    application, or if the Operator is exposed to user risk or criminal
                    prosecution, in particular as a result of breach of these Terms or the
                    Operator's policy. , if the user repeatedly infringes the intellectual property
                    rights of others, or if required by law.</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>4.
                    The Operator may terminate the Service or change, delete or block the content
                    or data shared within the Service, or stop providing the Service or any part
                    thereof if it decides that it is reasonably necessary to prevent negative legal
                    or regulatory effects, or to mitigate such effects.</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>5.
                    The operator's decision to remove content from the user's address will be
                    notified to the user with the possibility of reviewing the user's request to
                    restore the content, but unless the user seriously or repeatedly violates these
                    terms and conditions, or if this action exposes the operator to legal liability
                    or harms another user. , endangers or disrupts the integrity or</span><span
                    lang=EN-US style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>
                    operation of any of the operator's services, systems or products, or when this
                    is prevented by technical limitations of the Lives application or prohibited by
                    law.</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>6.
                    The user is entitled in the event that his account is canceled by mistake, or
                    if he wants to block or permanently delete the account, to request from the
                    operator and to proceed in this action according to the information of the
                    application. If a user requests the removal of content or their account, the
                    removal process will automatically begin no later than 30 days from the date of
                    the request. The content removal process can take up to 90 days from the start.
                    During the process of removing such content, this content is no longer visible
                    to other users, but remains subject to these terms and conditions. After
                    deleting the content, it may take another 90 days for the operator to delete
                    the contents of the user's account from backups and data recovery systems.</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>7.
                    The contents of the user's account will not be deleted within 90 days of the
                    start of the account deletion process or the deletion of the content in the
                    following situations:</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>7.1.
                    if the contents of the user's account in accordance with this license have been
                    used by others and have not been deleted, then the license will remain in
                    effect until such content is removed.</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>7.2.
                    if removal within 90 days is not possible due to technical limitations of the
                    operator's systems, in which case the removal process shall be completed by the
                    operator as soon as technically possible.</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>7.3.
                    if the removal would preclude other public interests:</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>(a)
                    investigate or identify illegal activity or breaches of the operator's
                    contractual terms and conditions (for example, identify or investigate misuse
                    of the operator's products or systems);</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>(b)
                    protect the safety and security of the operator's or other users' products and
                    systems;</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>(c)
                    comply with legal obligations such as the preservation of evidence;</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>(d)
                    comply with a request from a judicial or administrative authority, the State
                    Security Service;</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>&nbsp;</span></p>

                    <p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>8.
                    If the user deletes his account himself or if he blocks it, these terms and
                    conditions will be terminated as an agreement between the user and the
                    operator, but this section and the above section entitled &quot;Content
                    interventions, blocking or cancellation of the user's account&quot; will apply
                    even after cancellation. , block, or delete a user account.</span></p>

                    <p class=MsoNormal align=center style='text-align:center;text-indent:35.4pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>IX.</span></b></p>

                    <p class=MsoNormal align=center style='text-align:center;text-indent:35.4pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Responsibility</span></b></p>

                    <p class=MsoNormal style='text-indent:35.4pt'><span style='font-size:12.5pt;
                    line-height:107%;font-family:"Times New Roman",serif'>&nbsp;</span></p>

                    <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-18.0pt'><span
                    lang=EN-US style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>1.
                    The priority of the application, the social network &quot;Lives&quot; and its
                    team is to provide users with maximum service for the most safe and error-free
                    application. However, it is not possible to guarantee and monitor all
                    activities that may occur in the application. Therefore, any user who agrees to
                    the terms and conditions agrees to the use of the application &quot;Lives&quot;
                    and at the same time is aware of the possible risks that the application brings
                    and takes full responsibility for them (all forms of personal injury, death,
                    personal injury, sexual or sexual </span><span style='font-size:12.5pt;
                    line-height:107%;font-family:"Times New Roman",serif'>violence). other similar
                    subtext, fraudulently distorted and made statements) for which Social
                    technology sro does not accept responsibility.</span></p>

                    <p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-18.0pt'><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>2.
                    The company Social technology s.r.o. is not responsible in situations that are
                    not permitted or prohibited by law.</span></p>

                    <p class=MsoNormal align=center style='text-align:center;text-indent:35.4pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>X.</span></b></p>

                    <p class=MsoNormal align=center style='text-align:center;text-indent:35.4pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Possible
                    litigation</span></b></p>

                    <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-18.0pt'><span
                    lang=EN-US style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>1.
                    If the use of the service as a consumer gives rise to a claim or dispute over a
                    claim, the user and the operator agree that you can resolve your individual
                    claim or dispute against each other exclusively under the law of the Czech
                    Republic, provided that the subject matter and local jurisdiction the court of
                    the operator is responsible for hearing and resolving the dispute.</span></p>

                    <p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-18.0pt'><span
                    lang=EN-US style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>2.
                    If a claim or dispute arises between the user and the operator which relates to
                    the use of the service on any other basis, including access to the service or
                    use of the service for business or commercial purposes, the parties agree that
                    any such claim or dispute the claim will be resolved exclusively in accordance
                    with the legal order of the Czech Republic, with the court of the operator
                    having substantive and territorial jurisdiction to hear and decide the dispute.</span></p>

                    <p class=MsoNormal align=center style='text-align:center;text-indent:35.4pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>XI.</span></b></p>

                    <p class=MsoNormal align=center style='text-align:center;text-indent:35.4pt'><b><span
                    style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>Update
                    of these terms and conditions</span></b></p>

                    <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-18.0pt'><span
                    lang=EN-US style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>&nbsp;</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    lang=EN-US style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>The
                    user expressly understands that the application and social network Lives is
                    gradually improved in the future with the needs of this service and its changes
                    to improve user services, will bring new products, and therefore the terms and
                    conditions may change. Unless otherwise required by law, at least thirty days
                    before the changes to these terms and conditions are made, the operator shall
                    notify the changes to the terms and conditions at least through the service
                    provider and thus give the user the opportunity to familiarize themselves with
                    their contents. If the user continues to use the operator's services, the terms
                    and conditions, as amended, will apply to the current time when the service is
                    used by the user. If the user does not agree with these or any updated terms
                    and conditions, he is entitled to delete his account.</span></p>

                    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
                    lang=EN-US style='font-size:12.5pt;line-height:107%;font-family:"Times New Roman",serif'>&nbsp;</span></p>

                    <p class=MsoListParagraphCxSpLast align=right style='text-align:right;
                    text-indent:-18.0pt'><span lang=EN-US style='font-size:12.5pt;line-height:107%;
                    font-family:"Times New Roman",serif'>Social technology s.r.o., operator</span></p>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>

export default {
    name: "Security",
    computed: {
        lang(){
            return this.$i18n.locale;
        }
    }
}
</script>

<style scoped>

</style>

<style scoped>
    .WordSection1{
        font-family: 'Times New Roman', Times, serif !important;
    }
</style>